html {
  background-color: #000;
  overflow-y: auto
}

body {
  color: #eff0f1;
  animation: fadeInOpacity 0.5s
}

@-webkit-keyframes fadeInOpacity {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0
  }

  100% {
    opacity: 1
  }
}

a {
  color: #209cee
}

a:hover {
  color: #67c3ff
}

hr {
  background-color: #585858
}

code,
.message-body code {
  background-color: #000;
  border-radius: 5px
}

.title {
  color: #eff0f1
}

.subtitle {
  color: #bdc3c7
}

.subtitle strong {
  color: #bdc3c7
}

.input,
.select select,
.textarea {
  color: #eff0f1;
  border-color: #585858;
  background-color: #000
}

.input::-moz-placeholder,
.textarea::-moz-placeholder {
  color: #bdc3c7
}

.input::-webkit-input-placeholder,
.textarea::-webkit-input-placeholder {
  color: #bdc3c7
}

.input:-moz-placeholder,
.textarea:-moz-placeholder {
  color: #bdc3c7
}

.input:-ms-input-placeholder,
.textarea:-ms-input-placeholder {
  color: #bdc3c7
}

.input:not([disabled]):hover,
.select fieldset:not([disabled]) select:hover,
.select select:not([disabled]):hover,
.textarea:not([disabled]):hover,
fieldset:not([disabled]) .input:hover,
fieldset:not([disabled]) .select select:hover,
fieldset:not([disabled]) .textarea:hover {
  border-color: #209cee
}

.input.is-active,
.input.is-focused,
.input:active,
.input:focus,
.textarea.is-active,
.textarea.is-focused,
.textarea:active,
.textarea:focus {
  border-color: #209cee
}

.input[disabled],
.select fieldset[disabled] select,
.select select[disabled],
.textarea[disabled],
fieldset[disabled] .input,
fieldset[disabled] .select select,
fieldset[disabled] .textarea {
  border-color: #585858;
  background-color: #2f2f2f
}

.label {
  color: #eff0f1;
  font-weight: normal
}

.help {
  color: #bdc3c7
}

.progress {
  background-color: #585858
}

.button.is-info.is-hovered [class^="icon-"]::before,
.button.is-info.is-hovered [class*=" icon-"]::before,
.button.is-info:hover [class^="icon-"]::before,
.button.is-info:hover [class*=" icon-"]::before {
  fill: #fff
}

.checkbox:hover,
.radio:hover {
  color: #7f8c8d
}

.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #eff0f1
}

.select:not(.is-multiple):not(.is-loading):hover::after {
  border-color: #eff0f1
}

.message {
  background-color: #2f2f2f
}

.message-body {
  color: #eff0f1;
  border: 0
}

/* https://github.com/philipwalton/flexbugs#flexbug-3 */
.hero.is-fullheight > .hero-body {
  min-height: 100vh;
  height: 100%
}

/* https://github.com/philipwalton/flexbugs#flexbug-2 */
.hero.is-fullheight > .hero-body > .container {
  width: 100%
}
